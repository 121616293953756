<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
  >
       <v-card>
           <v-card-title primary-title class="letter-spacing">
               Edycja zawodów
           </v-card-title>
           <v-card-text class="py-2">
               <v-form v-model="valid">
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="form.name"
                                name="name"
                                label="Nazwa zawodów"
                                counter="150"
                                dense
                                outlined
                                :error-messages="errors.name"
                            ></v-text-field>
                       </v-col>
                   </v-row>
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="form.town"
                                name="name"
                                label="Miejscowość"
                                counter="30"
                                dense
                                outlined
                                :error-messages="errors.town"
                            ></v-text-field>
                       </v-col>
                   </v-row>
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0 ">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="form.date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.date"
                                    label="Data"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors.date"
                                ></v-text-field>
                                </template>
                                    <v-date-picker
                                    v-model="form.date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(form.date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                       </v-col>
                   </v-row>
               </v-form>
           </v-card-text>
           <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="grey"
                        class="text-none letter-spacing"
                        @click="dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="text-none"
                        @click="sendForm"
                    >
                        Zapisz
                    </v-btn>
           </v-card-actions>

       </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/mixins/dialog.js';

export default {
    mixins: [dialog],
    props:{
        zawody:{
            required: true,
        }
    },
    data: () => ({
        valid: false,
        menu: false,
        form: {
            name: '',
            town: '',
            date: '',
        },
        errors:{
            name: '',
            town: '',
            date: '',
        }
    }),
    watch:{
        zawody(val){
            if(val){
                this.form.name = val.name;
                this.form.town = val.town;
                this.form.date = val.date;
                console.log(val);
            }
        }
    },
    methods:{
        sendForm: function(){
            this.$http.post('/zawody/update', {
                    id: this.$props.zawody.id,
                    name: this.form.name,
                    town: this.form.town,
                    date: this.form.date
                })
                .then(() => {
                    this.dialog = false;
                    this.$emit('created');
                })
                .catch(err => {
                    this.errors = err.response.data.body;
                })
        },
        clearForm: function(){
            this.form = {
                name: '',
                town: '',
                date: '',
            };
             this.errors = {
                name: '',
                town: '',
                date: '',
            };
        }
    }
}
</script>

<style>

</style>