<template>
    <v-container>
        <v-card flat class="pb-2">
            <v-card-title primary-title class="letter-spacing">
                Zawody
                <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        name="search"
                        label="Wyszukaj zawodów"
                        outlined
                        dense
                        single-line
                        hide-details
                        clearable
                        clear-icon="fas fa-times"
                    ></v-text-field>
                <v-btn color="primary" elevation="0" small outlined @click="creating = true" class="ml-2" height="40px">
                    <v-icon left size="14">
                        fas fa-plus
                    </v-icon>
                    Dodaj zawody
                </v-btn>
            </v-card-title>
            <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                        :loading="loading"
                        sort-by="date"
                        :sort-desc="true"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="blue" class="blue lighten-5 table-icon" icon :to="'/zawody/'+item.id">
                                <v-icon small>fas fa-medal</v-icon>
                            </v-btn>
                            <v-btn color="blue" class="blue lighten-5 table-icon ml-2" icon @click="editing.zawody = item; editing.display = true;">
                                <v-icon small>fas fa-pencil-alt</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
            </v-card-text>
            <createModal :display="creating" @close="creating = false" @created="fetchAll"/>
            <editModal :display="editing.display" :zawody="editing.zawody" @created="fetchAll" @close="editing.display = false; editing.zawody = false;"/>
        </v-card>
    </v-container>
</template>

<script>
import createModal from '@/components/zawody/createModal.vue';
import editModal from '@/components/zawody/editModal.vue';

export default {
    components:{
        createModal,
        editModal
    },
    data: () => ({
        loading: false,
        creating: false,
        editing: {
            display: false,
            zawody: false,
        },
        search: '',
        headers:[
            { text: 'Lp.', value: 'id',  align: 'start'},
            { text: 'Nazwa', value: 'name' },
            { text: 'Miejscowość', value: 'town',  align: 'end' },
            { text: 'Data', value: 'date',  align: 'end'},
            { text: 'Zarządzaj', value: 'actions', align: 'end', sortable: false},
        ],
        items:[],
    }),
    methods:{
        fetchAll: function(){
            this.loading = true;
            this.$http.get('/zawody/getList')
                .then(res => {
                    this.items = res.data.body;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created(){
        let search = localStorage.getItem('zawodySearchCache');
        this.search = search!='null'?search:'';
        this.fetchAll();
    },
    destroyed(){
        localStorage.setItem('zawodySearchCache', this.search);
    }

}
</script>

<style>

</style>